import API from 'o365.modules.data.api.ts';
import logger from 'o365.modules.Logger.ts';

const viewDefMap: Map<string, Record<string, string | undefined>> = new Map();

export async function getViewDef(pViewName: string): Promise<Record<string, string | undefined> | undefined> {
    if (!viewDefMap.has(pViewName)) {
        try {
            const response = await API.requestPost(`/api/viewDefinition/${pViewName}`)
            viewDefMap.set(pViewName, response);
        } catch (ex) {
            logger.error(ex);
        }
    }
    return viewDefMap.get(pViewName);
}

export async function translateViewDef(pViewName: string): Promise<Record<string, string | undefined> | undefined> {
    if (!viewDefMap.has(pViewName)) {
        try {
            const response = await API.requestGet(`/api/translations/ViewDefinition/${pViewName}.json`, { showErrorDialog: false })
            viewDefMap.set(pViewName, response);
        } catch (ex) {
            logger.error(ex);
        }
    }
    return viewDefMap.get(pViewName);
}

export type FieldType = 'string' | 'number' | 'datetime' | 'date' | 'time' | 'uniqueidentifier' | 'json' | 'bit';
export type FieldDataType = 'int' | 'decimal' | 'float' | 'real' | 'bigint' | 'smallint' | 'tinyint' | 'datetime2' | 'datetime' | 'datetimeoffset' | 'date' | 'time' | 'nvarchar' | 'nchar' | 'char' | 'varchar' | 'bit' | 'uniqueidentifier';
export function convertType(pType: FieldDataType | null): FieldType {
    switch (pType) {
        case 'int':
        case 'decimal':
        case 'float':
        case 'real':
        case 'bigint':
        case 'smallint':
        case 'tinyint':
            return 'number';
        case 'datetimeoffset':
        case 'datetime':
        case 'datetime2':
            return 'datetime';
        case 'nvarchar':
        case 'nchar':
        case 'char':
        case 'varchar':
            return 'string';
        case 'bit':
        case 'uniqueidentifier':
        case 'date':
        case 'time':
            return pType;
        case null:
        case undefined:
            return 'string';
        default:
            return pType;
        //throw new Error(`Could not convert data type ${pType} into a field type`);
    }
}

